import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import styled from "styled-components"
import parse from "html-react-parser"
import Layout from "../components/layout"
import BluePageContent from "../components/StyledElements/bluePageContent"
import LinkedIn from "../images/linkedin.svg"
import Facebook from "../images/facebook.svg"
import Web from "../images/web.svg"
import Seo from "gatsby-plugin-wpgraphql-seo"

const medarbejder = ({ data }) => {
  const { allWpMedarbejder } = data
  const { wpPage } = data

  return (
    <>
      <Seo post={wpPage} />
      <Layout>
        <BluePageContent>
          <h1> {wpPage.title}</h1>
          <IntroText>{parse(`${wpPage.content}`)}</IntroText>
          <GridWrap>
            {allWpMedarbejder.nodes.map((WpmedarbejderNode) => (
              <div key={WpmedarbejderNode.id}>
                <GatsbyImage
                  image={
                    WpmedarbejderNode.ACFmedarbejder.billede.localFile
                      .childImageSharp.gatsbyImageData
                  }
                  alt={WpmedarbejderNode.ACFmedarbejder.billede.altText}
                />
                <MemberNavn>{WpmedarbejderNode.title}</MemberNavn>
                <MemberTitel>
                  {WpmedarbejderNode.ACFmedarbejder.titel}
                </MemberTitel>
                <MemberSocials>
                  {WpmedarbejderNode.ACFmedarbejder.socials.linkedin && (
                      <element />
                    ) && (
                      <a
                        href={WpmedarbejderNode.ACFmedarbejder.socials.linkedin}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <MemberSocialIcon src={LinkedIn} alt="Linkedin icon" />
                      </a>
                    )}
                  {WpmedarbejderNode.ACFmedarbejder.socials.web && <element /> && (
                    <a
                      href={WpmedarbejderNode.ACFmedarbejder.socials.web}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <MemberSocialIcon src={Web} alt="Web icon" />
                    </a>
                  )}
                  {WpmedarbejderNode.ACFmedarbejder.socials.facebook && (
                      <element />
                    ) && (
                      <a
                        href={WpmedarbejderNode.ACFmedarbejder.socials.facebook}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <MemberSocialIcon src={Facebook} alt="Facebook icon" />
                      </a>
                    )}
                </MemberSocials>
              </div>
            ))}
          </GridWrap>
        </BluePageContent>
      </Layout>
    </>
  )
}

export default medarbejder

export const query = graphql`
  {
    allWpMedarbejder(
      sort: { fields: date, order: ASC }
    ) {
      nodes {
        id
        title
        date
        ACFmedarbejder {
          titel
          socials {
            facebook
            linkedin
            web
          }
          billede {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
        }
      }
    }
    wpPage(databaseId: { eq: 480 }) {
      databaseId
      title
      content
      seo {
        title
        metaDesc
        focuskw
        metaKeywords
        metaRobotsNoindex
        metaRobotsNofollow
        opengraphTitle
        opengraphDescription
        opengraphImage {
          altText
          sourceUrl
          srcSet
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
            }
          }
        }
        twitterTitle
        twitterDescription
        twitterImage {
          altText
          sourceUrl
          srcSet
        }
        canonical
        cornerstone
        schema {
          articleType
          pageType
          raw
        }
      }
    }
  }
`

const GridWrap = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 3fr));
  grid-gap: 25px;
`

const MemberNavn = styled.h2`
  text-align: center;
  padding-top: 20px;
  margin-top: 0px !important;
  margin-bottom: 0px;
`

const MemberTitel = styled.p`
  text-align: center;
  padding-left: 0px !important;
  padding-bottom: 20px !important;
`

const MemberSocials = styled.div`
  text-align: center;
`

const MemberSocialIcon = styled.img`
  height: 20px;
  width: 20px;
  margin-right: 5px;
  margin-bottom: 0px;
`

const IntroText = styled.div`
  padding-left: 0px !important;

  & p {
    padding-left: 0px !important;
  }
`
